import "../design/App.css";
import React, { useEffect, useState } from "react";
import podium from "../design/assets/podium.png";
import MobilePage from "../modals/mobile";
import tokenimg from "../design/assets/token.png";
import ModalHistory from "../modals/modalHistory";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import fetchAllRedux from "../redux/fetchAllRedux";


const League = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState({});

  const { league } = useSelector((state) => state.league);
  const { user } = useSelector((state) => state.user);


  useEffect(() => {
    if (user.length === 0) {
      dispatch(fetchAllRedux());
    }
  }, [user, dispatch]);

  const openModal = () => {
    const newData = {
      title: 'Train Player',
      name: "Jude Bellingham",
      skill: "Defence",
      price: "0.0003"
    };
    setData(newData);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  function truncateName(fullName) {
    return fullName.length > 15 ? fullName.slice(0, 10) + "..." : fullName;
  }

  return (
    <>
      <ModalHistory show={isModalOpen} onClose={closeModal} data={data}/>
      <MobilePage data={true}>
        <div id="league">
          <h1 className="header">
            <div className="row d-flex justify-content-between">
              <div className="col-2 my-auto">
                <h1 className="title m-0">LEAGUE</h1>
              </div>
              <div className="col-2 d-flex" style={{flexDirection:"row-reverse"}}>
                <div className="budgetWrapper">
                  <img className="token" src={tokenimg} alt="" />
                  <span className="budget">{user?.data?.user?.balance !== undefined ? user.data.user.balance : 'Loading...'}</span>
                </div>
              </div>
            </div>
          </h1>
          <div className="container d-flex justify-content-center p-0">
            <div className="row d-flex justify-content-center" style={{width:"100%"}}>
                <div className="col-12 p-0">
                  <div className="podiumWrapper">
                    <button className="history" onClick={()=>openModal()} ><i className="fa-solid fa-clock-rotate-left"></i></button>
                    <div className="row m-auto" style={{width:"100%"}}>
                      <div className="col-12 p-0 d-flex justify-content-center">
                        <img src={podium} className="podium" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="leaderboardWrapper">
                    <div className="row" style={{width:"100%"}}>
                      <div className="col-12 p-0 d-flex justify-content-center">
                        <div className="d-flex row justify-content-center text-center title" style={{color:"white", width:"100%"}}>
                          <div className="col-2"></div>
                          <div className="col-5 text-start ">Player Name</div>
                          <div className="col-5 text-start ">Score</div>
                          
                        </div>
                      </div>
                      <div className="col-12 p-0 scroll">
                        <ul>
                          {league.data && Object.entries(league.data.top_players.order).map(([user, score], key) => (
                              <li style={{color:"white"}} key={key}>
                                <div className="d-flex row text-center d-flex justify-content-center">
                                <div className=" col-2">1</div>
                                <div className=" col-5 text-start">{truncateName(user)}</div>
                                <div className=" col-5 text-start">{score}</div>
                                </div>
                              </li>
                            ))}
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </MobilePage>
    </>
  );
}

export default League;
